export const info = {'campaignId': 'The first 6 chars',
'startDate': 'The date from which you want to track data (inclusive)',
'endDate': 'The date from which you want to stop track data (inclusive)',
'observationType': 'What fields from the `coolblue-marketing-prod.google_analytics_4.sessions` table in BigQuery do you need to define the sessions you want to include in your observations (denominator).',
'obsEventCategory': 'This fields corresponds with hits.event.eventcategory in the `coolblue-marketing-prod.google_analytics_4.sessions` table. It uses regex. We will count all sessions that match all regular expressions.',
'obsEventAction': 'This fields corresponds with hits.event.eventaction in the `coolblue-marketing-prod.google_analytics_4.sessions` table. It uses regex. We will count all sessions that match all regular expressions.',
'obsEventLabel': 'This fields corresponds with hits.event.eventlabel in the `coolblue-marketing-prod.google_analytics_4.sessions` table. It uses regex. We will count all sessions that match all regular expressions.',
'obsPagepath': 'This fields corresponds with hits.page.pagepath in the `coolblue-marketing-prod.google_analytics_4.sessions` table. It uses regex. We will count all sessions that match all regular expressions.',
'obsBigQueryField': 'You can select a column from `coolblue-marketing-prod.google_analytics_4.sessions` to witch you can apply a regex match statement. We will count all sessions that match all regular expressions.',
'obsExpression': 'The regex you want to apply to the previously select column.',
'successesType': 'What fields from the `coolblue-marketing-prod.google_analytics_4.sessions` table in BigQuery do you need to define the sessions you want to include in your successes (numerator).',
'sucEventCategory': 'This fields corresponds with hits.event.eventcategory in the `coolblue-marketing-prod.google_analytics_4.sessions` table. It uses regex. We will count all sessions that match all regular expressions.',
'sucEventAction': 'This fields corresponds with hits.event.eventaction in the `coolblue-marketing-prod.google_analytics_4.sessions` table. It uses regex. We will count all sessions that match all regular expressions.',
'sucEventLabel': 'This fields corresponds with hits.event.eventlabel in the `coolblue-marketing-prod.google_analytics_4.sessions` table. It uses regex. We will count all sessions that match all regular expressions.',
'sucPagepath': 'This fields corresponds with hits.page.pagepath in the `coolblue-marketing-prod.google_analytics_4.sessions` table. It uses regex. We will count all sessions that match all regular expressions.',
'sucBigQueryFieldOne': 'You can select a column from `coolblue-marketing-prod.google_analytics_4.sessions` to witch you can apply a regex match statement. We will count all sessions that match all regular expressions.',
'sucExpressionOne': 'The regex you want to apply to the previously select column.',
'sucBigQueryFieldTwo': 'You can select a column from `coolblue-marketing-prod.google_analytics_4.sessions` to which you can apply a regex match statement. We will count all sessions that match all regular expressions.',
'sucExpressionTwo': 'The regex you want to apply to the previously select column.',
'dimensionAlias': 'The (column) name that you want to use for your dimension. Please note that this column name is also used as a Biqguery column name in staging tables. This is mandatory for example for device.devicecategory, to rename it to device.',
'dimensionDefinition': "This is the definition of the dimension, which can be a column reference or a SQL statement, such as a CASE statement. If you want to use the event_params field, copy this format and change the case statement to your preference: COUNT(DISTINCT (SELECT total_session_id FROM UNNEST(event_params) AS event_params WHERE REGEXP_CONTAINS(LOWER(CAST(CASE WHEN event_params.key LIKE 'ga_session_id' then '1' END AS STRING)), r'(1)') LIMIT 1))",
'impact': 'The impact on the EBITDA can be calculated via our impact calculator which you can find on the home page of the toolkit.',
'hostname': 'This optional field can be used to track your visits based on subsidiary. Please be aware that you use Regex.',
'page_location': 'This field can be used to track your visits based on the URL. Please be aware that you use Regex.',
'event_params_value': 'This field equals the event_params.value field in the GA4 events table. If you added custom tracking in Flirt, you need to fill in the event_params.value that you specified in Flirt. We strongly recommend to use the campaign name as event_params.value in FLirt. Please be aware that you use Regex.',
'event_params_key': 'This field equals the event_params.key field in the GA4 events table. If you added custom tracking in Flirt, you need to fill in here: test_name. Please be aware that you use Regex.',
'event_name': 'This field equals the event_name field in the GA4 events table. If you added custom tracking in Flirt, you need to fill in here: show_split_test. Please be aware that you use Regex.',
'custom_metric_name': 'This field can be used if you want to monitor a custom metric. You can name it according to your preferences but you need to use underscores (_) instead of spaces!',
'context': 'This field equals the context field in the GA4 events table and tells us the location of the event. Examples include product_page, filter_page or checkout_overview_page. Please be aware that you use Regex.',
'feature': 'This field equals the feature field in the GA4 events table and says something extra about the location of the event. Examples include order_block and payment_methods. Please be aware that you use Regex.',
'sub_feature': 'This field equals the feature field in the GA4 events table and says something extra about the location of the event. Please be aware that you use Regex.',
'custom_context': 'This field equals the context field in the GA4 events table and tells us the location of the event. Examples include product_page, filter_page or checkout_overview_page. Please be aware that you use Regex.',
'custom_feature': 'This field equals the feature field in the GA4 events table and says something extra about the location of the event. Examples include order_block and payment_methods. Please be aware that you use Regex.',
'custom_sub_feature': 'This field equals the feature field in the GA4 events table and says something extra about the location of the event. Please be aware that you use Regex.',
'custom_event_name': 'This field equals the event_name field in the GA4 events table. Please be aware that you use Regex.',
'excluded_pagepaths': 'In this field you can add URLs from which you do NOT want to track visits. Please be aware that you use Regex.',

}

export default {info}